<template>
  <div>
    <mds-page-shell :logo="getProductLogo()" :logo-responsive="getProductLogo()" logo-href="/"
      logo-alt="Back to home page">
      <div class="createaccount-main">
        <div class="createaccount-registration-main">
          <div class="createaccount-registration-form">
            <div class="createaccount-registration-form-content-container">
              <div class="createaccount-title-for-registration-form">
                <h1 class="createaccount-registration-page-title">Create your account</h1>
              </div>
              <div class="createaccount-registration-top-part-text">
                <p class="createaccount-registration-form-text-first">
                  Welcome to <i>Morningstar {{ currentProductName }}</i>. In just minutes, you will have immediate access
                  to your digital
                  subscription.
                </p>
                <p v-if="!this.$store.state.user.LoggedIn" class="createaccount-signSection">
                  If you already subscribe to a Morningstar product,
                  <a class="createaccount-registration-hyperlink-test" target="_blank" @click="login()">sign in here</a>
                  for a faster checkout.
                </p>
                <p class="createaccount-print-registration-section">
                  If you are a print subscriber visiting for the first time, complete
                  a
                  <router-link class="createaccount-registration-hyperlink-test" :to="{
                    name: 'print-registration',
                  }">one-time registration</router-link>
                </p>
              </div>
              <div class="createaccount-loader">
                <mds-loader v-if="isLoading" aria-label="Large Loader" size="large"></mds-loader>
              </div>
              <div
                :class="!this.$store.state.user.LoggedIn ? 'createaccount-registration-form-fields' : 'createaccount-registration-form-fields-after-login'">
                <mds-input class="input-tab" size="large" label="First Name" type="text" required :error="firstNameError"
                  v-model.trim="firstname"></mds-input>
                <span class="FormsError" v-if="firstNameError">First name is required</span>
                <br />
                <mds-input v-model.trim="lastname" type="text" size="large" :error="lastNameError" label="Last Name"
                  required></mds-input>
                <span class="FormsError" v-if="lastNameError">Last name is required</span>
                <br />
                <mds-input v-model.trim="email" :error="emailError" size="large" label="Email" type="email" required
                  :disabled="existingUser"></mds-input>
                <span class="FormsError" v-if="emailError">{{
                  emailErrorMessage
                }}</span>
                <br />
                <mds-input v-model.trim="password" :error="passwordError" password size="large" label="Password" required
                  v-if="!disabled"></mds-input>
                <span class="FormsError" v-if="passwordError">{{ passwordErrorMessage }}</span>
                <div class="createaccount-registration-password-div" v-if="!disabled">
                  <p v-bind:class="[
                    this.validatePasswordError
                      ? 'registration-password-text-error'
                      : 'registration-password-text',
                    'registration-password-text',
                  ]">
                    Your password must be 8 or more characters, with one upper or
                    lower case letter, one special character (e.g., ?$%!@#) AND one
                    number (1-9). It cannot contain more than 3 sequential or
                    repeating characters, username, mstar, or Morningstar.
                  </p>
                </div>

                <mds-input password v-model.trim="verifypassword" size="large" label="Verify Password"
                  :error="verifypasswordError" v-if="!disabled" required></mds-input>
                <span class="FormsError" v-if="verifypasswordError">Password verification does not match the password
                  entered</span>
              </div>
              <div class="createaccount-registration-border"></div>
              <div class="createaccount-registration-checkboxes">
                <mds-checkbox size="small" value="accept" v-model="emailoptions">Get emails about new Morningstar products
                  and features.
                </mds-checkbox>
                <br />
                <mds-checkbox size="small" value="accept" v-model="checked">Clicking continue indicates agreement to the
                  Morningstar Investor
                  Newsletter
                  <router-link class="createaccount-registration-terms-link" target="_blank"
                    :to="{ name: 'user-agreement' }">Terms of Use</router-link> and <a target="_blank" class="createaccount-registration-terms-link" href="https://www.morningstar.com/company/privacy-policy">Morningstar Privacy Policy</a>.<span class="required">*</span>
                </mds-checkbox>
                <mds-alert @mds-alert-dismissed="closeAlert()" variation="error" class="agreetermError"
                  v-if="agreetermError" :tinted="agreetermError">
                  You must agree to the Morningstar Terms of Use
                </mds-alert>
              </div>
              <div class="createaccount-registration-button-container">
                <div>
                  <mds-button-container left-aligned>
                    <mds-button variation="secondary" size="large" type="button" @click="
                      $router.push({
                        name: 'combined-home',
                        params: { currentproduct: 'Common' },
                      })
                      ">
                      Cancel
                    </mds-button>
                    <div class="continue-button">
                      <mds-button variation="primary" size="large" type="button" right-aligned
                        @click.native.stop="ContinueForm" :disabled="!checked">
                        Continue</mds-button>
                    </div>
                  </mds-button-container>
                </div>
              </div>
            </div>
            <div class="createaccount-register-page-bottom"></div>
          </div>
        </div>
      </div>
    </mds-page-shell>
  </div>
</template>

<script>
import MdsPageShell from '@mds/page-shell';
import MdsInput from "@mds/input";
import MdsCheckbox from "@mds/checkbox";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsAlert from "@mds/alert";
import MdsLoader from "@mds/loader";
import EncryptDecryptMixin  from "../../mixins/EncryptDecryptMixin";
import { fetchData } from '@/utilities/API/apiUtility';

export default {
  name: "registration-section",
  mixins:[EncryptDecryptMixin],
  components: {
    MdsPageShell,
    MdsInput,
    MdsCheckbox,
    MdsButton,
    MdsButtonContainer,
    MdsAlert,
    MdsLoader,
  },
  data() {
    return {
      currentProductName:"",
      currentProducts: "",
      referID: null,
      ProductCode: null,
      userDetails: [],
      userRoles: [],
      userEmail: null,
      OmahaHostURL: process.env.VUE_APP_OmahaHostURL,
      MEIAccess: false,
      MDIAccess: false,
      MSIAccess: false,
      MFIAccess: false,
      userID: null,
      firstname: null,
      lastname: null,
      email: null,
      password: null,
      verifypassword: null,
      disabled: false,
      checked: false,
      emailoptions: false,
      firstNameError: false,
      lastNameError: false,
      emailError: false,
      emailErrorMessage: "",
      passwordError: false,
      passwordErrorMessage: "",
      verifypasswordError: false,
      validatePasswordError: false,
      existingUser: false,
      agreetermError: false,
      currentProduct: null,
      isLoading: true,

      Country: "",
      Address_1: "",
      Address_2: "",
      City: "",
      State: "",
      Zip: "",
      MPhone: "",
      DPhone: "",
      MobileAlert: false,
    };
  },
  methods: {
    async getEmailNotificationList(customerId) {
      this.isLoading = true;
      var GetEmailNotificationUrl = process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_EmailNotification;
      GetEmailNotificationUrl = GetEmailNotificationUrl.replace('{customerId}', customerId);
      try {
        return fetchData(GetEmailNotificationUrl)
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  var responseData = response.data;
                  if (responseData) {
                    for (var i in responseData) {
                      var alertOptions = responseData[i];
                      if (alertOptions) {
                        if (alertOptions.OptionID == 19 && alertOptions.OptionValue == 1) {
                          this.emailoptions = true;
                        }
                        if (alertOptions.OptionID == 491 && alertOptions.OptionValue == 1) {
                          this.MobileAlert = true;
                        }
                      }
                    }
                  }
                }

              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },
    closeAlert() {
      this.agreetermError = false;
    },
    getProductLogo() {
      var images = require.context(
        "../../../assets/Images",
        false,
        /\.svg$/
      );
      return images("./Common.svg");
    },
    getSelectedProduct(productCode) {
      if (productCode.includes("NM00000001")) {
        this.currentProducts = "MSI";
        this.currentProductName = "StockInvestor";
      }
      if (productCode.includes("NDI0000001")) {
        this.currentProducts = this.currentProducts == "" ? "MDI" : ",MDI";
        this.currentProductName = "DividendInvestor";
      }
      if (productCode.includes("NF00000001")) {
        this.currentProducts = this.currentProducts == "" ? "MFI" : ",MFI";
        this.currentProductName = "FundInvestor";
      }
      if (productCode.includes("NET0000001")) {
        this.currentProducts = this.currentProducts == "" ? "MEI" : ",MEI";
        this.currentProductName = "ETFInvestor";
      }
      this.currentProductName = this.currentProducts.includes(",") ? "Newsletters" : this.currentProductName;
    },
    async getUserDetails(userID) {
      var getUserDetailsAPIURL =
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserDetails;
      getUserDetailsAPIURL = getUserDetailsAPIURL.replace("{userId}", userID);
      try {
        return fetchData(getUserDetailsAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.userDetails = response.data;
                  this.saveDataTolocalStorage(this.userDetails);
                  this.firstname = this.userDetails["FirstName"];
                  this.lastname = this.userDetails["LastName"];
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally();
      } catch (ex) {
        console.log(ex.message);
      }
    },
    async checkuseremailExists(userEmail) {
      var getUserRoleAPIURL =
        process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetUserEmail;
      getUserRoleAPIURL = getUserRoleAPIURL.replace("{UserEmail}", userEmail);
      try {
        return fetchData(getUserRoleAPIURL, {
            headers: {
              Accept: "application/json",
            },
          })
          .then((response) => {
            if (response) {
              if (response.status == 200) {
                if (response.data) {
                  this.userRoles = response.data;
                  this.userID = this.userRoles["UserID"];
                  if (this.userID != null) {
                    return true;
                  } else {
                    return false;
                  }
                }
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (ex) {
        console.log(ex.message);
      }
    },
    validatePassword(password) {
      if (password.length >= 8 && password.length <= 24) {
        if (/\d+/.test(password) && /[A-Za-z]/.test(password)) {
          if (/[^\da-zA-Z]/.test(password)) {
            if (/(.)\1{3}/.test(password)) {
              return false;
            }
            else if (/(?:abcd|bcde|cdef|defg|efgh|fghi|ghij|hijk|ijkl|jklm|klmn|lmno|mnop|nopq|opqr|pqrs|qrst|rstu|stuv|tuvw|uvwx|vwxy|wxyz)/.test(password)) {
              return false;
            }
            else if (/^(?=.*?mstar)/.test(password.toLowerCase())) {
              return false;
            }
            else if (/^(?=.*?morningstar)/.test(password.toLowerCase())) {
              return false;
            }
            else if (this.firstname != null && this.firstname != "") {
              if (password.toLowerCase().includes(this.firstname.toLowerCase())) {
                return false;
              }
              else {
                return true;
              }
            }
            else {
              return true;
            }
          } else {
            return false;
          }
        }
        else {
          return false;
        }
      }
      else {
        return false;
      }
    },
    async ContinueForm() {
      

      this.isLoading = true;

      this.$gtag.event('create_account', {
        'product': this.currentProducts,
        'userid': this.userID,
        
      })      

      if (
        this.firstname == null ||
        this.firstname == "" ||
        this.firstname.length > 60
      ) {
        this.firstNameError = true;
      }
      if (
        this.lastname == null ||
        this.lastname == "" ||
        this.lastname.length > 60
      ) {
        this.lastNameError = true;
      }

      if (
        this.email == null ||
        this.email == ""
      ) {
        this.emailError = true;
        this.emailErrorMessage = "Email is required";
      }
      else if (!/^[^@]+@\w+(\.\w+)+\w$/.test(this.email)) {
        this.emailError = true;
        this.emailErrorMessage = "Email is not in correct format";
      }
      if (!this.checked) {
        this.agreetermError = true;
      }
      if (!this.existingUser) {
        if (this.email != null) {
          if (await this.checkuseremailExists(this.email)) {
            this.emailError = true;
            this.emailErrorMessage =
              "This email is already in use. Please either sign in or choose a different email to continue with your purchase";
          }
        }
        if (this.password == null || this.password == "") {
          this.passwordError = true;
          this.passwordErrorMessage = "Password is required";
        }
        if (this.password != null || this.password == "") {
          if (await !this.validatePassword(this.password)) {
            this.validatePasswordError = true;
          }
          else {
            this.passwordError = false;
            this.validatePasswordError = false;
          }
        }
        if (
          this.verifypassword == null ||
          this.verifypassword == "" ||
          this.password != this.verifypassword
        ) {
          this.verifypasswordError = true;
        }
        else {
          this.verifypasswordError = false;
        }
      }
      if (
        !this.firstNameError &&
        !this.lastNameError &&
        !this.emailError &&
        !this.passwordError &&
        !this.validatePasswordError &&
        !this.verifypasswordError &&
        !this.agreetermError
      ) {

        if (this.$store.state.user.LoggedIn) {
          if (this.userDetails != null) {
            const data = {
              FirstName: this.firstname,
              LastName: this.lastname,
              Email: this.email,
              Password: "",
              EmailAlert: this.emailoptions,
              Country: this.userDetails["Country"],
              Address_1: this.userDetails["Address_1"],
              Address_2: this.userDetails["Address_2"],
              City: this.userDetails["City"],
              State: this.userDetails["State"],
              Zip: this.userDetails["ZipCode"],
              MPhone: this.userDetails["Mphone"],
              DPhone: this.userDetails["Dphone"],
              MobileAlert: this.MobileAlert,
              UserID: this.userID,
              OmahaCustID: this.userDetails["CustomerID"],
            };
            this.saveDataTolocalStorage(data);
          }
        }
        else {
          const data = {
            FirstName: this.firstname,
            LastName: this.lastname,
            Email: this.email,
            Password: this.password,
            EmailAlert: this.emailoptions,
            Country: this.Country,
            Address_1: this.Address_1,
            Address_2: this.Address_2,
            City: this.City,
            State: this.State,
            Zip: this.Zip,
            MPhone: this.MPhone,
            DPhone: this.DPhone,
            MobileAlert: this.MobileAlert,
          };
          this.saveDataTolocalStorage(data);

        }
        this.isLoading = false;
        this.$router.push({
          name: "subscription",
          params: { referid: this.referID, productcode: this.ProductCode },
        });
      } else {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    login() {
      this.$auth0.loginWithRedirect();
    },
    saveDataTolocalStorage(data) {
      data = JSON.stringify(data);
      data = this.Encrypt(data);
      localStorage.setItem("userDetails", data);
    },
    async getDataFromlocalStorage() {
      if (this.$store.state.user.LoggedIn) {
        await this.getUserDetails(this.userID);
        if (this.userDetails["CustomerID"] != null && this.userDetails["CustomerID"] != "") {
          await this.getEmailNotificationList(this.userDetails["CustomerID"])
        }
      }
      else {
        var data = this.Decrypt(localStorage.getItem("userDetails"));
        if (data != "" && data != null) {
          data = JSON.parse(data);
          if (data.FirstName != null) {
            this.firstname = data.FirstName;
            this.lastname = data.LastName;
            this.email = data.Email;
            this.emailoptions = data.EmailAlert;
            this.Country = data.Country;
            this.Address_1 = data.Address_1;
            this.Address_2 = data.Address_2;
            this.City = data.City;
            this.State = data.State;
            this.Zip = data.Zip;
            this.MPhone = data.MPhone;
            this.DPhone = data.DPhone;
            this.MobileAlert = data.MobileAlert;
          }
        }
      }
    },
  },

  async mounted() {
    this.getSelectedProduct(
      this.$route.params.productcode
    );     
    this.LoggedIn = this.$auth0.isAuthenticated && this.$session.exists();
    this.$store.state.user.LoggedIn = this.LoggedIn;
    window.scrollTo(0, 0);
    this.$store.state.layout.isHeaderRequired = false;
    this.$store.state.layout.isFooterRequired = false;
    this.referID = this.$route.params.referid;
    this.ProductCode = this.$route.params.productcode;
    if (this.$store.state.user.LoggedIn) {
      this.existingUser = true;
      (this.disabled = true),
        (this.productDataRoles =
          this.$auth0.user["https://morningstar.com/uim_roles"]);
      if(this.productDataRoles != undefined && this.productDataRoles !=null) {
      this.MEIAccess = this.productDataRoles.includes("EI_MEMBER");
      this.MDIAccess = this.productDataRoles.includes("DI_MEMBER");
      this.MSIAccess = this.productDataRoles.includes("SI_MEMBER");
      this.MFIAccess = this.productDataRoles.includes("FI_MEMBER");
      }
      this.userEmail = this.$auth0.user["email"];
      this.email = this.userEmail;
      this.userID = this.$auth0.user["https://morningstar.com/mstar_id"];
      if (this.ProductCode == "NM00000001" && this.MSIAccess) {
        this.$router.push({
          name: "subscriptionexists",
          params: { referid: this.referID, productcode: this.ProductCode },
        });
      } else if (this.ProductCode == "NDI0000001" && this.MDIAccess) {
        this.$router.push({
          name: "subscriptionexists",
          params: { referid: this.referID, productcode: this.ProductCode },
        });
      } else if (this.ProductCode == "NF00000001" && this.MFIAccess) {
        this.$router.push({
          name: "subscriptionexists",
          params: { referid: this.referID, productcode: this.ProductCode },
        });
      } else if (this.ProductCode == "NET0000001" && this.MEIAccess) {
        this.$router.push({
          name: "subscriptionexists",
          params: { referid: this.referID, productcode: this.ProductCode },
        });
      }

    }
    await this.getDataFromlocalStorage();
    this.isLoading = false;
  },
  watch: {
    firstname(newVal, oldVal) {
      if (this.password != null && this.password != " ") {
        this.passwordError = false;
        this.validatePasswordError = false;
      }
      if (newVal != oldVal) {
        if (newVal == "" || newVal.length > 60 || newVal == null) {
          this.firstNameError = true;
        } else {
          this.firstNameError = false;
        }
      }
    },
    lastname(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == "" || newVal.length > 60 || newVal == null) {
          this.lastNameError = true;
        } else {
          this.lastNameError = false;
        }
      }
    },
    email(newVal, oldVal) {
      if (newVal != oldVal) {
        if (
          newVal == "" ||
          newVal == null
        ) {
          this.emailError = true;
          this.emailErrorMessage = "Email is required";
        }
        else if (newVal.length > 60 ||
          !/^[^@]+@\w+(\.\w+)+\w$/.test(newVal)) {
          this.emailError = true;
          this.emailErrorMessage = "Email is not in correct format";
        } else {
          this.emailError = false;
        }
      }

    },
    password(newVal, oldVal) {
      this.verifypasswordError = false;
      if (newVal != oldVal) {
        if (newVal == null || newVal == "") {
          this.passwordError = true;
          this.validatePasswordError = false;
          this.passwordErrorMessage = "Password is required";
        }
        else if (!this.validatePassword(newVal)) {
          this.passwordError = false;
          this.validatePasswordError = true;
        }
        else {
          this.validatePasswordError = false;
        }
      }
    },
    verifypassword(newVal, oldVal) {
      if (newVal != oldVal) {
        if (newVal == null || newVal == "" || this.password != newVal) {
          this.verifypasswordError = true;
        } else {
          this.verifypasswordError = false;
        }
      }
    },
    checked(newVal, oldVal) {
      if (newVal != oldVal) {
        if (!newVal) {
          this.agreetermError = true;
        } else {
          this.agreetermError = false;
        }
      }
    },
  },
};
</script>
<style scoped>
.required {
  color: #ca0000;
  text-decoration: none;
}

.createaccount-main {
  height: 100%;
  min-height: 100vh;
  background: #f2f2f2;
}

.createaccount-register-page-bottom {
  background: linear-gradient(360deg,
      #ffffff 12.47%,
      rgba(255, 255, 255, 0) 112.75%);
  opacity: 1;
  z-index: 2;
  transform: rotate(180deg);
  height: 50px;
}

.createaccount-loader {
  position: absolute;
  width: "276px";
  height: "69px";
  left: 38.92%;
  right: 37.92%;
}

.createaccount-signSection {
  margin-top: 5% !important;
}

.createaccount-print-registration-section {
  margin-top: 5% !important;
}

.agreetermError {
  margin-top: 5% !important;
}

.createaccount-product-logo {
  margin-left: 4%;
  margin-top: 1%;
}

.createaccount-registration-main {
  max-width: 1280px;
  padding: auto;
  margin: auto;
}

.createaccount-registration-form {
  max-width: 480px;
  margin: 0 auto;
  background: #fff;
  height: 100%;
  min-height: 100vh;
}

.createaccount-registration-form-content-container {
  width: 380px;
  margin: 0 auto;
}

.createaccount-registration-page-title {
  margin-top: 0px;
  margin-bottom: 0px;
  height: 44px;
  padding-top: 57px;
  font-weight: 250;
  font-size: 28px;
  line-height: 34px;
}

.createaccount-registration-top-part-text {
  width: 100%;
  height: 177px;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
}

.createaccount-registration-form-text-first {
  margin-top: 20%;
}

.createaccount-registration-hyperlink-test {
  cursor: pointer;
  text-decoration: none;
  border-bottom: 1px solid #000000;
  color: #000000;
}

.createaccount-registration-form-fields {
  width: 100%;
  margin-top: 5% !important;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.createaccount-registration-form-fields-after-login {
  width: 100%;
  margin-top: -10% !important;
  font-size: 16px;
  line-height: 21px;
  color: #1e1e1e;
}

.createaccount-registration-password-div {
  width: 100%;
  height: 88px;
  margin-left: 0px;
}

.registration-password-text {
  font-weight: 400;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 0px;
}

.registration-password-text-error {
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 0px;
  color: red;
}

.createaccount-registration-border {
  margin-top: 31px;
  width: 100%;
  border-bottom: 1px solid #cccccc;
}

.createaccount-registration-checkboxes {
  margin-top: 10px;
  width: 100%;
}

.createaccount-registration-button-container {
  margin-top: 40px;
  width: 100%;
}

.createaccount-registration-link {
  border-bottom: 1px solid #000000;
  color: #000000;
}

.createaccount-registration-terms-link {
  color: #1e1e1e;
}

.createaccount-registration-link {
  border: none;
  cursor: default;
  font-weight: 300;
  font-size: 20px;
  line-height: 23px;
  color: #ffffff;
  text-decoration: none;
}

.continue-button {
  margin-left: auto;
  order: 2;
}

.FormsError {
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  color: #ca0000;
  display: block;
  margin-top: 2%;
}

@media only screen and (max-width: 480px) {
  .createaccount-registration-form-content-container {
    width: 90%;
    margin: 0 auto;
  }
  .createaccount-registration-page-title {
    padding-top: 28px;
    font-weight: 250;
    font-size: 28px;
    line-height: 34px;
}
.createaccount-registration-form-text-first {
  margin-top: 10%;
}
}
</style>
<style>
.createaccount-registration-form-fields .mds-label.mds-label--large .mds-label__text {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
  font-weight: 700 !important;
  margin-bottom: 2% !important
}

.createaccount-registration-form-fields-after-login .mds-label.mds-label--large .mds-label__text {
  font-size: 16px !important;
  font-weight: 300 !important;
  line-height: 21px !important;
  font-weight: 700 !important;
  margin-bottom: 2% !important
}
.createaccount-registration-checkboxes .mds-checkbox__visible-wrap{
  box-shadow: none !important;
}
</style>