<template>
    <div>
        <div v-if="isMobileContentToggle" class="main-mdi-performance-section-responsive">
            <div class="mdi-performance-divBorder-responsive"></div>
            <div class="common-span-cls">Net Composite Performance</div>
            <div style="margin-top:32px;" class="mdi-performance-image-composite-responsive">
                <img alt="composite portfolio performance" :src="MDIcompositeperformanceimageUrl" />
            </div>
            <div style="margin-top:3px;font-size:14px;font-weight: 400;line-height: 18px;color: #1E1E1E; width: 93%;">
                The Dividend Select and Dividend non-MLP Select strategies are managed by Morningstar Investment
                Management LLC. Morningstar Investment Management’s subsidiary offers these strategies through a
                discretionary investment advisory service (“Advisory Service”). The "Net of Fees" performance shown
                reflects the deduction of a model fee equal to the maximum advisory fee that could be charged to the
                strategy through Morningstar Investment Services’ advisory program, brokerage or other commissions, and
                other expenses that a client paid in connection with the advisory services they received, and are
                calculated by deducting these fees from the gross returns.
            </div>

            <div class="mdi-performance-divBorder-responsive"></div>
            <div class="common-span-cls">Portfolio Performance</div>
            <div class="mdi-performance-performance-graph-responsive" href="/mdiDisclosure">
                <img alt="Performance Graph" :src="MDIportfolioperformanceimageUrl" />
            </div>
            <router-link class="mdi-performance-disclosure-link" target="_blank"
                :to="{ name: 'mdi-disclosure', params: { currentproduct: this.$session.get('current-product') } }">
                Click here for important performance information.
            </router-link>
            <div class="mdi-performance-divBorder-responsive"></div>

            <div class="common-span-cls">Resources</div>
            <div style="margin-top:32px;">
                <div class="mdi-performance-heading">Resources</div>
                <mds-table style="width: 90%;" class="mdi-tbl-font">
                    <mds-tbody>
                        <mds-tr>
                            <mds-td>
                                <router-link class="mdi-resources-links"
                                    :to="{ name: 'archive', params: { currentproduct: this.CurrentProduct } }">
                                    Past Issues
                                </router-link>
                            </mds-td>
                        </mds-tr>
                        <mds-tr>
                            <mds-td>

                                <a class="mdi-resources-links" @click="toggle = !toggle"
                                    @keydown.enter="toggle = !toggle" tabindex="0">Investment Strategy</a>

                            </mds-td>
                        </mds-tr>
                        <mds-tr v-for="row in resourcesdata" :key="row.ID">
                            <mds-td v-if="row.PdfPlacement === '3'">
                                <a class="mdi-resources-links" tabindex="0"
                                    @keydown.enter="DownloadReport(row.PdfName, 'RESOURCES')"
                                    v-on:click="DownloadReport(row.PdfName, 'RESOURCES')">{{ row.PdfTitle }}</a>
                            </mds-td>
                        </mds-tr>
                        <mds-tr v-for="row in resourcesdata" :key="row.ID">
                            <mds-td v-if="row.PdfPlacement === '4'">
                                <a class="mdi-resources-links" tabindex="0"
                                    @keydown.enter="DownloadReport(row.PdfName, 'RESOURCES')"
                                    v-on:click="DownloadReport(row.PdfName, 'RESOURCES')">{{ row.PdfTitle }}</a>
                            </mds-td>
                        </mds-tr>
                        <mds-tr>
                            <mds-td>
                                <a class="mdi-resources-links" href="mailto:mdi@morningstar.com">Ask The Editor</a>
                            </mds-td>
                        </mds-tr>
                    </mds-tbody>
                </mds-table>

                <div class="mdi-performance-heading" style="margin-top: 28px;">Bonus Reports</div>
                <mds-table style="width: 90%;" class="mdi-tbl-font">
                    <mds-tbody>
                        <mds-tr v-for="row in pdfdata" :key="row.ID">
                            <mds-td>
                                <a class="mdi-resources-links" tabindex="0"
                                    @keydown.enter="DownloadReport(row.PdfName, 'BonusReports')"
                                    v-on:click="DownloadReport(row.PdfName, 'BonusReports')">{{ row.PdfTitle }}</a>
                            </mds-td>
                        </mds-tr>

                    </mds-tbody>
                </mds-table>

                <div class="mdi-performance-heading" style="margin-top: 28px;">Portfolio and Watchlist</div>
                <mds-table style="width: 90%;" class="mdi-tbl-font">
                    <mds-tbody>
                        <mds-tr>
                            <mds-td>
                                <router-link class="mdi-resources-links"
                                    :to="{ name: 'dividendSelectPort', params: { currentproduct: this.CurrentProduct } }">
                                    Dividend Select Portfolio
                                </router-link>
                            </mds-td>
                        </mds-tr>
                        <!-- <mds-tr>
                            <mds-td>
                                <router-link class="mdi-resources-links"
                                    :to="{ name: 'dividendDeferPort', params: { currentproduct: this.CurrentProduct } }">
                                    Dividend Select Deferred Portfolio
                                </router-link>
                            </mds-td>
                        </mds-tr> -->
                        <mds-tr>
                            <mds-td>
                                <router-link class="mdi-resources-links"
                                    :to="{ name: 'incomeBellWethers', params: { currentproduct: this.CurrentProduct } }">
                                    Bellwethers Watchlist
                                </router-link>
                            </mds-td>
                        </mds-tr>
                    </mds-tbody>
                </mds-table>
            </div>


            <!--<mds-dialog v-model="toggle" title="Investment Strategy" class="investment-srategy-modal">
                        <p style="text-align: justify">
                            This strategy takes a concentrated, best-ideas approach when investing in select common stocks of dividend-paying companies and other securities such as American Depositary Receipts, master limited partnerships, and real estate investment trusts. It seeks firms with wide or narrow moats that we believe are in a stronger competitive position than their peers and that are trading at a reasonable price.
                        </p>
                    </mds-dialog>-->

            <mds-modal size="small" width="900px" aria-label="Small Modal" v-model="toggle" actionRequired
                class="investment-strategy-modal">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">Investment Strategy</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" :size="6">
                        <p>This strategy takes a concentrated, best-ideas approach when investing in select common
                            stocks of dividend-paying
                            companies and other securities such as American Depositary Receipts, master limited
                            partnerships, and real
                            estate investment trusts. It seeks firms with wide or narrow moats that we believe are in a
                            stronger competitive
                            position than their peers and that are trading at a reasonable price.</p>
                    </mds-section>
                </mds-section>
            </mds-modal>

        </div>



        <div class="main-div-mdi-performance" v-else>
            <mds-layout-grid style="width:100%">
                <mds-row>

                    <mds-col :cols="3" :cols-at-s="6" :cols-at-m="6" :cols-at-l="6" :cols-at-xl="3"
                        class="first-layout-for-mdi">
                        <div class="mdi-performance-divBorder-one"></div>
                        <div class="common-span-cls">Resources</div>
                        <div style="margin-top:32px;" class="resources-main-div-for-width">
                            <div class="resources-main-div-one">
                                <div class="mdi-performance-heading">Resources</div>


                                <mds-table class="mdi-tbl-font">
                                    <mds-tbody>
                                        <mds-tr>
                                            <mds-td>
                                                <router-link class="mdi-resources-links"
                                                    :to="{ name: 'archive', params: { currentproduct: this.CurrentProduct } }">
                                                    Past Issues
                                                </router-link>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr>
                                            <mds-td>
                                                <a class="mdi-resources-links" @click="toggle = !toggle"
                                                    @keydown.enter="toggle = !toggle" tabindex="0">Investment
                                                    Strategy</a>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr v-for="row in resourcesdata" :key="row.ID">
                                            <mds-td v-if="row.PdfPlacement === '3'">
                                                <a class="mdi-resources-links" tabindex="0"
                                                    @keydown.enter="DownloadReport(row.PdfName, 'RESOURCES')"
                                                    v-on:click="DownloadReport(row.PdfName, 'RESOURCES')">{{
                                                        row.PdfTitle }}</a>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr v-for="row in resourcesdata" :key="row.ID">
                                            <mds-td v-if="row.PdfPlacement === '4'">
                                                <a class="mdi-resources-links" tabindex="0"
                                                    @keydown.enter="DownloadReport(row.PdfName, 'RESOURCES')"
                                                    v-on:click="DownloadReport(row.PdfName, 'RESOURCES')">{{
                                                        row.PdfTitle
                                                    }}</a>
                                            </mds-td>
                                        </mds-tr>
                                        <mds-tr>
                                            <mds-td>
                                                <a class="mdi-resources-links" href="mailto:mdi@morningstar.com">Ask The
                                                    Editor</a>
                                            </mds-td>
                                        </mds-tr>
                                    </mds-tbody>
                                </mds-table>




                                <div class="mdi-performance-heading" style="margin-top: 28px;">Bonus Reports</div>

                                <mds-table class="mdi-tbl-font">
                                    <mds-tbody>
                                        <mds-tr v-for="row in pdfdata" :key="row.ID">
                                            <mds-td>
                                                <a class="mdi-resources-links" tabindex="0"
                                                    @keydown.enter="DownloadReport(row.PdfName, 'BonusReports')"
                                                    v-on:click="DownloadReport(row.PdfName, 'BonusReports')">{{
                                                        row.PdfTitle
                                                    }}</a>
                                            </mds-td>
                                        </mds-tr>

                                    </mds-tbody>
                                </mds-table>
                            </div>

                            <div class="main-div-class-bonus-watchlist">
                                <div class="mdi-performance-heading" style="margin-top: 28px;">Portfolio and Watchlist
                                </div>

                                <mds-table class="mdi-tbl-font-portfolio">
                                    <mds-tbody>
                                        <mds-tr>
                                            <mds-td>
                                                <router-link class="mdi-resources-links"
                                                    :to="{ name: 'dividendSelectPort', params: { currentproduct: this.CurrentProduct } }">
                                                    Dividend Select Portfolio
                                                </router-link>
                                            </mds-td>
                                        </mds-tr>
                                        <!-- <mds-tr>
                                            <mds-td>
                                                <router-link class="mdi-resources-links"
                                                    :to="{ name: 'dividendDeferPort', params: { currentproduct: this.CurrentProduct } }">
                                                    Dividend Select Deferred Portfolio
                                                </router-link>
                                            </mds-td>
                                        </mds-tr> -->
                                        <mds-tr>
                                            <mds-td>
                                                <router-link class="mdi-resources-links"
                                                    :to="{ name: 'incomeBellWethers', params: { currentproduct: this.CurrentProduct } }">
                                                    Bellwethers Watchlist
                                                </router-link>
                                            </mds-td>
                                        </mds-tr>
                                    </mds-tbody>
                                </mds-table>
                            </div>
                        </div>
                    </mds-col>

                    <mds-col :cols="6" :cols-at-s="3" :cols-at-m="3" :cols-at-l="6" :cols-at-xl="6"
                        class="second-layout-for-mdi">
                        <div class="net-composite-div-main">
                            <div class="mdi-performance-divBorder-second"></div>
                            <div class="common-span-cls">Net Composite Performance</div>
                            <div class="mdi-performance-image-composite">
                                <img alt="composite portfolio performance" :src="MDIcompositeperformanceimageUrl" />
                            </div>
                            <div
                                style="margin-top:3px;font-size:14px;font-weight: 400;line-height: 18px;color: #1E1E1E;">
                                The Dividend Select strategy is managed by Morningstar
                                Investment
                                Management LLC. Morningstar Investment Management’s subsidiary offers these strategies
                                through a
                                discretionary investment advisory service (“Advisory Service”). The "Net of Fees"
                                performance
                                shown reflects the deduction of a model fee equal to the maximum advisory fee that could
                                be
                                charged to the strategy through Morningstar Investment Services’ advisory program,
                                brokerage or
                                other commissions, and other expenses that a client paid in connection with the advisory
                                services they received, and are calculated by deducting these fees from the gross
                                returns.
                            </div>
                        </div>
                    </mds-col>

                    <mds-col :cols="3" :cols-at-s="3" :cols-at-m="3" :cols-at-l="3" :cols-at-xl="3"
                        class="third-layout-for-mdi">
                        <div class="net-composite-div-main-one">
                            <div class="mdi-performance-divBorder-third"></div>
                            <div class="common-span-cls">Portfolio Performance</div>
                            <div class="mdi-performance-performance-graph" href="/mdiDisclosure">
                                <img class="mdi-performance-performance-graph-img" alt="Performance Graph"
                                    :src="MDIportfolioperformanceimageUrl" />
                            </div>
                            <router-link class="mdi-performance-disclosure-link" target="_blank"
                                :to="{ name: 'mdi-disclosure', params: { currentproduct: this.$session.get('current-product') } }">
                                Click here for important performance information.
                            </router-link>
                        </div>
                    </mds-col>
                </mds-row>
            </mds-layout-grid>
            <!--<mds-dialog v-model="toggle" title="Investment Strategy">
            <p style="text-align: justify">
                This strategy takes a concentrated, best-ideas approach when investing in select common stocks of dividend-paying companies and other securities such as American Depositary Receipts, master limited partnerships, and real estate investment trusts. It seeks firms with wide or narrow moats that we believe are in a stronger competitive position than their peers and that are trading at a reasonable price.
            </p>
        </mds-dialog>-->
            <mds-modal size="small" width="900px" aria-label="Small Modal" v-model="toggle" actionRequired
                class="investment-strategy-modal">
                <mds-section class="with-nested-sections" border-position="bottom" bold>
                    <template #mds-section-title>
                        <span id="title-id">Investment Strategy</span>
                    </template>
                    <template #mds-section-actions>
                        <mds-button-container right-aligned>
                            <mds-button @click="toggle = !toggle" variation="icon-only" icon="remove">Close</mds-button>
                        </mds-button-container>
                    </template>
                    <mds-section border="none" :size="6">
                        <p>This strategy takes a concentrated, best-ideas approach when investing in select common
                            stocks of
                            dividend-paying companies and other securities such as American Depositary Receipts, master
                            limited
                            partnerships, and real estate investment trusts. It seeks firms with wide or narrow moats
                            that we
                            believe are in a stronger competitive position than their peers and that are trading at a
                            reasonable
                            price.</p>
                    </mds-section>
                </mds-section>
            </mds-modal>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import {
    MdsLayoutGrid,
    MdsRow,
    MdsCol
} from "@mds/layout-grid";
import {
    MdsTable,
    MdsTbody,
    MdsTr,
    MdsTd,
} from "@mds/data-table";
//import MdsDialog from "@mds/dialog";
import MdsModal from '@mds/modal';
import MdsSection from "@mds/section";
import { MdsButton, MdsButtonContainer } from '@mds/button';
import GA4TrackingMixin from "../../../mixins/GA4TrackingMixin";
import { fetchData } from "@/utilities/API/apiUtility";

export default {
    name: "mdi-performance-section",
    components: {
        MdsLayoutGrid,
        MdsRow,
        MdsCol,
        //MdsDialog,
        MdsModal,
        MdsSection,
        MdsButton,
        MdsButtonContainer,
        MdsTable,
        MdsTbody,
        MdsTr,
        MdsTd
    },
    data() {
        return {
            MDIportfolioperformanceimageUrl: null,
            MDIcompositeperformanceimageUrl: null,
            ImageDataGet: process.env.VUE_APP_Newsletters_Utilities + process.env.VUE_APP_GetImagedata,
            MDIportfolioperformance: 'MDI Portfolio Performance Image', // Change this to your desired placement
            MDIcompositeperformance: 'MDI Composite Performance Image', // Change this to your desired placement
            toggle: false,
            DownloadPdfUrl: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_DownloadPdfUrl,
            CurrentProduct: this.$session.get("current-product"),
            isMobileContentToggle: null,
            viewPortWidth: null,
            CurrentDate: new Date(),
            GetAllMiscReportURL: process.env.VUE_APP_BackEndAPI + process.env.VUE_APP_GetAllMiscReport,
            pdfdata: "",
            resourcesdata: "",
        }
    },
    mixins: [GA4TrackingMixin],
    methods: {
        async MDIperformanceImagesdata() {
            try {
                let Url = this.ImageDataGet;
                Url = Url.replace("{ProductCode}", "MDI");
                fetchData(Url)
                    .then((response) => {
                        const images = response.data.fileData;
                        const filteredImagePortfolio = images.find(image => image.description.includes(this.MDIportfolioperformance));
                        const filteredImageComposite = images.find(image => image.description.includes(this.MDIcompositeperformance));

                        if (filteredImagePortfolio) {
                            this.MDIportfolioperformanceimageUrl = `${filteredImagePortfolio.s3URL}?t=${new Date().getTime()}`;
                        }

                        if (filteredImageComposite) {
                            this.MDIcompositeperformanceimageUrl = `${filteredImageComposite.s3URL}?t=${new Date().getTime()}`;
                        }

                    })


            } catch (ex) {
                console.log(ex.message);
            }

        },
        DownloadReport(FileName, Type) {
            this.$gtag.event('download_pdf', {
                'product': 'MDI',
                'issue_date': this.CurrentDate,
                'issue_title': FileName,
                'type': "Bonus",
                'user_type': localStorage.getItem("userType_MDI")
            })

            try {
                let Url = this.DownloadPdfUrl;
                Url = Url.replace('{ProductCode}', this.CurrentProduct);
                Url = Url.replace('{FileName}', FileName);
                Url = Url.replace('{Type}', Type);
                console.log(Url);
                fetchData(Url).then((response) => {
                    if (response) {
                        if (response.status == 200) {
                            if (response.data) {
                                var results = response.data;
                                if (results) {
                                    if (results.FilePath) {
                                        const link = document.createElement("a");
                                        link.setAttribute("href", results.FilePath);
                                        link.setAttribute("target", "_blank");
                                        link.setAttribute("download", FileName);
                                        link.click();
                                    }
                                }
                            }
                        }
                    }
                }).catch((error) => {
                    console.log(error);

                })
                    .finally();
            } catch (ex) {
                console.log(ex.message);
            }
        },
        GetAllPdfUpload() {
            try {
                fetchData(this.GetAllMiscReportURL).then((response) => {
                    if (response) {
                        if (response.status == 200) {
                            if (response.data) {
                                this.pdfdata = response.data.filter(row => {
                                    return row.PdfType === 'BonusReports' && row.ProductCode === 'MDI';
                                });
                                this.resourcesdata = response.data.filter(row => {
                                    return row.PdfType === 'Resources' && row.ProductCode === 'MDI';
                                });
                            }
                        }
                    }
                });
            } catch (ex) {
                console.log(ex.message);
            }
        },
    },

    created() {
        this.MDIperformanceImagesdata()
    },

    mounted() {
        this.isMobileContentToggle = this.$store.state.responsive.isMobileContent;
        this.GetAllPdfUpload();
        this.getUserDetailsMixin(this.CurrentProduct);
    },

    computed: mapState({
        isMobileContent: state => state.responsive.isMobileContent,
        isMobileContentAlias: 'isMobileContent',
        isMobileContentState(state) {
            return state.responsive.isMobileContent
        }

    }),

    watch: {
        isMobileContentState() {
            this.isMobileContentToggle = this.isMobileContentState;
        }
    }
}
</script>

<style scoped>
.mdi-performance-divBorder {
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
}

.mdi-performance-divBorder-one {
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
}

.mdi-performance-divBorder-second {
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
}

.mdi-performance-divBorder-third {
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
}

.mdi-performance-disclosure-link {
    margin-top: 3px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #1E1E1E;
    text-decoration: none;
}

.common-span-cls {
    font-style: normal;
    font-weight: 300;
    font-size: 23px;
    line-height: 27px;
    color: #1E1E1E;
    margin-top: 12px;
}

.mds-layout-grid .mds-layout-grid__col--6-at-1200 {
    max-width: 53%;
    flex-basis: 53%;
}

.mdi-performance-image-composite {
    width: 100%;
}

.mdi-performance-image-composite img {
    width: 100%;
    aspect-ratio: auto;
    margin-top: 28px;
}

.mdi-performance-performance-graph {
    width: 400px;
    margin-left: -2px;
    margin-top: 22px;
    margin-bottom: 10px;
}

.mdi-performance-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #1E1E1E;
    margin-bottom: 12px;
}

a.mdi-performance-text-anchor:hover {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
}

a.mdi-performance-text-anchor:active {
    color: #666666;
    text-decoration: none;
}

.mdi-performance-text-anchor,
a.mdi-performance-text-anchor:link,
span.mdi-performance-text-anchor {
    color: #1E1E1E;
    border-top: none;
}

.mdi-performance-text-anchor {
    color: #1E1E1E;
    text-decoration: none;
    width: 274px;
    border-top: 1px dotted #ff0000;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
}

.mdi-performance-Solid175Gray {
    border-top: 1px Solid #cccccc;
}

.heightInnerText {
    margin-top: 3px;
}

.mdi-performance-text-anchor:hover {
    color: #ff0000 !important;
    text-decoration: none;
    cursor: pointer;
}

.routerLink {
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
}

.router-link-exact-active {
    margin-top: 10px;
    box-shadow: inset 0 -4px 0 0 red;
    position: relative;
    padding-bottom: 13px;
}

.mdi-tbl-font tr td {
    color: #666666;
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    pointer-events: all;
}

.mdi-tbl-font tr td a {
    color: #666666;
    text-decoration: none;
    font-family: "Univers", HelveticaNeue, "Helvetica Neue", Helvetica, Arial,
        sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 21px;
    cursor: pointer;
    pointer-events: all;
}

.mdi-tbl-font tr td:hover {
    color: #ff0000;
    text-decoration: none;
}

.mds-td:first-child {
    padding-left: 2px;
}

.mdi-tbl-font tr td a:hover {
    color: #ff0000;
    text-decoration: none;
    cursor: pointer;
}
</style>
<style>
/*
    .mdi-performance-text-anchor:hover {
    color: #ff0000 !important;
    text-decoration: none;
    cursor: pointer;
    }
    */
.investment-strategy-modal .mds-section .mds-section__header-container {
    margin-bottom: 5px;
}

@media only screen and (min-width: 1280px) and (max-width: 12800px) {

    .main-div-mdi-performance {
        width: 1280px;
        margin: auto;
        padding: auto;
    }
}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .main-mdi-performance-section-responsive {
        width: 95%;
        margin-left: 16px;
    }

    .mdi-performance-performance-graph-responsive {
        width: 90%;
        margin-top: 15px;
    }

    .mdi-performance-performance-graph-responsive img {
        width: 100%;
        margin-left: -5px;
    }

    .investment-strategy-modal .mds-modal__content {
        overflow-y: scroll;
    }
}

@media only screen and (min-width: 600px) and (max-width: 6000px) {
    .main-mdi-performance-section-responsive {
        display: none !important;
        ;
    }
}

.mdi-performance-image-composite-responsive {
    width: 90%;
}

.mdi-performance-image-composite-responsive img {
    width: 100%;
    margin-bottom: 10px;
    height: 100%;
}

.mdi-performance-divBorder-responsive {
    width: 90%;
    margin-top: 40px;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
}

.mdi-performance-divBorder-responsive {
    width: 90%;
    border-top: 1px solid #5e5e5e;
    transform: rotate(180deg);
}

@media only screen and (min-width: 600px) and (max-width: 1200px) {
    .mdi-performance-divBorder {
        width: 110%;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
        margin-top: 20px;
    }
}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .main-div-mdi-performance {

        width: 95%;
        margin: auto;
        padding: auto;
        margin-left: 5%;
    }

    .mdi-tbl-font {
        width: 180%;
    }

    .mdi-tbl-font-portfolio {
        width: 183%;
    }

    .mdi-performance-divBorder-one {

        width: 200%;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }

    .mdi-performance-divBorder-second {
        width: 103%;
        margin-top: 20px;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }


    .resources-main-div-for-width {
        width: 110%;
    }

    .net-composite-div-main {
        width: 100%;
    }

    .net-composite-div-main-one {
        width: 180%;
        margin-left: 0%;
    }


    .mdi-performance-divBorder-third {
        width: 105%;
        margin-top: 20px;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }
}

@media only screen and (min-width: 1200px) and (max-width: 12000px) {
    .mdi-tbl-font {
        width: 100%;
    }

    .mdi-tbl-font-portfolio {
        width: 100%;
    }
}


@media only screen and (min-width: 769px) and (max-width: 1200px) {
    .main-div-mdi-performance {
        width: 95%;
        margin: auto;
        padding: auto;
        margin-left: 5%;
    }

    .mdi-tbl-font {
        width: 115%;
    }

    .mdi-tbl-font-portfolio {
        width: 170%;
    }

    .resources-main-div-for-width {
        width: 160%;
    }

    .mdi-performance-image-composite-responsive {
        width: 90%;
    }

    .mdi-performance-image-composite-responsive img {
        width: 100%;
        margin-bottom: 10px;
        height: 50%;
    }

    .mdi-performance-divBorder-one {
        width: 200%;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }

    .mdi-performance-divBorder-second {
        width: 103%;
        margin-top: 20px;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }

    .mdi-performance-divBorder-third {
        width: 100%;
        margin-top: 20px;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }

    .net-composite-div-main-one {
        width: 160%;
        margin-left: 0%;
    }

}

@media only screen and (min-width: 0px) and (max-width: 600px) {
    .main-div-mdi-performance {
        display: none !important;
        ;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1280px) {
    .main-div-mdi-performance {
        width: 92%;
        margin: auto;
        padding: auto;
        margin-left: 5%;
    }

    .mdi-performance-divBorder-third {
        width: 100%;
        margin-top: 0px;
        border-top: 1px solid #5e5e5e;
        transform: rotate(180deg);
    }
}


@media only screen and (min-width: 1601px) and (max-width: 12800px) {
    .net-composite-div-main-one {
        width: 100%;
        margin-top: -221.3%;
        margin-left: 330%;
    }

    .net-composite-div-main {
        width: 92%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .main-div-class-bonus-watchlist {
        width: 100%;
        margin-top: -303px;
        padding-left: 65%;
        margin-bottom: 150px;
    }

    .resources-main-div-one {
        width: 53%;
    }

}

@media only screen and (min-width: 600px) and (max-width: 768px) {
    .main-div-class-bonus-watchlist {


        padding-bottom: 20px;

    }

}

.investment-srategy-modal {
    width: 80%;
    padding-left: 8%;
}
</style>